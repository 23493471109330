import React from 'react';
import PropTypes from 'prop-types';
import newsPosts from '../data/news';
import { withStyles } from '../tools/withStyles';
import { Main } from '../components/Main';
import { Post } from '../components/Post';
import { Secuence } from '../components/Secuence';
import { Text } from '../components/Text';
import { Button } from '../components/Button';

const filterTypes = ['All', 'Website', 'Plugin'];

const styles = theme => ({
  root: {},
  filterContainer: {
    marginBottom: 10,
    textAlign: 'center'
  },
  filterButton: {
    color: '#cccccc',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: 0.5,
    textShadow: '0 0 5px #27e1fa',
    fontFamily: 'Orbitron, sans-serif',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    backgroundColor: 'transparent',
    border: 'none',
    transition: 'color 250ms ease-out',
    '&:hover': {
      color: '#87f7fc'
    },
    '@media (max-width: 600px)': {
      fontSize: '11px'
    }
  }
});

class News extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    sounds: PropTypes.object.isRequired
  };

  state = {
    selectedFilter: 'All'
  };

  handleFilterChange = (filter) => {
    this.setState({ selectedFilter: filter });
  };

  componentDidUpdate (prevProps, prevState) {
    if (prevState.selectedFilter !== this.state.selectedFilter) {
      this.forceUpdate();
    }
  }

  render () {
    const { classes, sounds } = this.props;
    const { selectedFilter } = this.state;

    let filteredPosts = [...newsPosts];
    if (selectedFilter !== 'All') {
      filteredPosts = filteredPosts.filter(post => post.type === selectedFilter);
    }

    return (
      <Main className={classes.root}>
        <Secuence stagger key={selectedFilter}>
          <header>
            <h1 style={{ textAlign: 'center' }}><Text>Projects</Text></h1>
          </header>
          <div className={classes.filterContainer}>
            {filterTypes.map(filter => (
              <Button
                key={filter}
                theme={classes}
                className={`${classes.filterButton} ${selectedFilter === filter ? 'active' : ''}`}
                onClick={() => this.handleFilterChange(filter)}
              >
                {filter}
              </Button>
            ))}
          </div>
          {filteredPosts.map((post, index) => (
            <Post
              key={index}
              audio={{ silent: index > 4 }}
              data={{ ...post, id: 'post' + index }}
            />
          ))}
        </Secuence>
      </Main>
    );
  }
}

export default withStyles(styles)(News);
