module.exports = [
  {
    title: 'Spirosg.dev V1',
    message: `Version 1 of my portfolio, a dynamic website showcasing my work. The website features a modern and interactive design inspired by the CyberPunk game, incorporating custom animations and effects synced with music to provide visitors with an engaging user experience.`,
    link: 'https://v1.spirosg.dev/',
    image: '/images/projects/projects-8.webp',
    type: 'Website',
    tags: ['THREE.JS', 'BOOTSTRAP', 'PHP', 'JS']
  },
  {
    title: 'Lemonokhpos.gr',
    message: `Lemonokhpos.gr is a kindergarten website developed using WordPress and Elementor Page Builder. The website presents a single-page layout with scroll animations, offering visitors a dynamic and engaging user experience.`,
    link: 'https://www.lemonokhpos.gr/',
    image: '/images/projects/projects-6.webp',
    type: 'Website',
    tags: ['WordPress', 'WooCommerce']
  },
  {
    title: 'Niktech.gr',
    message: `Niktech.gr is an e-commerce platform developed utilizing WooCommerce. Custom plugins were seamlessly integrated to enhance product management efficiency. These plugins enabled automatic importing and deleting of products directly from suppliers' XML, alongside dynamic pricing and stock adjustments. Moreover, the platform was equipped to export products in real-time to XML format, facilitating integration with the Skoutz.gr marketplace.`,
    link: 'https://www.niktech.gr/',
    image: '/images/projects/projects-7.webp',
    type: 'Website',
    tags: ['WordPress', 'WooCommerce', 'PHP', 'JS']
  },
  {
    title: 'WooBlacklist',
    message: `WooBlacklist is a simple WooCommerce plugin designed to manage users who fail to pick up their pay on delivery orders by blacklisting them.`,
    link: 'https://github.com/spiros-g/WooBlacklist',
    image: '',
    type: 'Plugin',
    tags: ['WordPress', 'Plugin', 'PHP']
  },
		  {
    title: 'Simple Cost Of Goods',
    message: `This WordPress plugin facilitates the management of Cost of Goods within WooCommerce. It provides essential functionality for tracking and maintaining the cost of goods for products, aiding in inventory management and pricing strategies..`,
    link: 'https://github.com/spiros-g/cost-of-goods',
    image: '',
    type: 'Plugin',
    tags: ['WordPress', 'Plugin', 'PHP']
  },
  {
    title: 'Polisafety.gr',
    message: `Polisafety.gr is an e-commerce platform developed using WooCommerce. Custom plugins were integrated to automate product management, including importing from suppliers' XML and dynamic pricing/stock adjustments. Ensured REST API security and facilitated ERP software integration.`,
    link: 'https://www.polisafety.gr/',
    image: '/images/projects/projects-1.webp',
    type: 'Website',
    tags: ['WordPress', 'WooCommerce', 'PHP', 'JS']
  },
  {
    title: 'e-Vida.gr',
    message: `e-vida.gr is an e-commerce platform developed using WooCommerce. Custom plugins were integrated to automate product management, including importing from suppliers' XML and dynamic pricing/stock adjustments. Ensured REST API security and facilitated ERP software integration.`,
    link: 'https://www.e-vida.gr/',
    image: '/images/projects/projects-2.webp',
    type: 'Website',
    tags: ['WordPress', 'WooCommerce', 'PHP', 'JS']
  },
  {
    title: 'Firegloves.org',
    message: `Firegloves.org is a website developed using WordPress and Elementor Page Builder, catering to English and Greek-speaking audiences. With WooCommerce integration, the site offers products from Firegloves, a renowned manufacturer of safety gear and fire-resistant gloves since 1981.`,
    link: 'https://www.firegloves.org/',
    image: '/images/projects/projects-3.webp',
    type: 'Website',
    tags: ['WordPress', 'WooCommerce', 'PHP', 'JS']
  }
];
